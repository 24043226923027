<template>
  <div>
    <!--  面包屑  -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>商品管理</el-breadcrumb-item>
      <el-breadcrumb-item>商品列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!--  卡片视图区  -->
    <el-card class="box-card">
      <el-alert :closable="false"
                title="注意：只允许为第三级分类设置相关参数" type="warning" show-icon>
      </el-alert>

      <el-row>
        <el-col>
          <span>选择商品分类：</span>
          <!--    选择商品分类的级联选择框      -->
          <el-cascader v-model="selectefKeys" :options="catelist" clearable
                       :props="cascaderProps" @change="parentCateChanged"></el-cascader>
        </el-col>
      </el-row>

      <el-button type="primary" size="mini" :disabled="isBtnDisabled" @click="addParamsOpen" v-if="is_add" icon="el-icon-document-add">添加</el-button>
      <el-table :data="mantParamslist"  border stripe>
        <!--  这是展开行   -->
        <el-table-column type="expand" ref="edxaasd" >
          <template slot-scope="scope">
            <div class="inline-box">
              <div v-for="(item,index) in scope.row.children" :key="index" class="tag-div" >
              <el-input
                  class="input-new-tag" style="width: 450px;"
                  v-if="editable[index]"
                  v-model="item.gav_name"
                  :ref="'editableInput'+ item.gan_id + index"
                  size="small"
                  placeholder="请输入"
                  @keyup.enter.native="handleEditableInputConfirm(item, index,scope.row)"
                  @change="handleEditableInputConfirm(item, index ,scope.row)"
                  @blur="handleEditableInputBlur(item, index)"
                  maxlength="50"
                  show-word-limit
              ></el-input>
              <el-tag  :closable="is_goodsattrvalue_del" @click="showEditTagInput(index,scope.row,item)" v-else
              @close="handleClose(index,scope.row)" :disable-transitions="true">
                {{item.gav_name}}
              </el-tag>
            </div>
            </div>
<!--            <el-input class="input-new-tag" v-if="inputVisble" v-model="inputValue" ref></el-input>-->
            <el-input
                class="input-new-tag" style="width: 450px;"
                v-if="scope.row.inputVisible"
                v-model="scope.row.inputValue"
                ref="saveTagInput"
                size="small"
                @keyup.enter.native="handleInputConfirm(scope.row)"
                @blur="handleInputConfirm(scope.row)"
            >
            </el-input>
            <el-button v-else-if="is_goodsattrvalue_add" class="button-new-tag" size="small"
                       @click="showInput(scope.row)">新增属性</el-button>
          </template>
        </el-table-column>
        <el-table-column type="index" ></el-table-column>
        <el-table-column label="属性名称" prop="gan_name"></el-table-column>
        <el-table-column label="添加人" prop="add_uid"></el-table-column>
        <el-table-column label="添加时间" prop="add_date"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <!-- 编辑 -->
            <el-button type="primary" size="mini" v-if="is_edit" icon="el-icon-edit"
                       @click="editParams(scope.row.gan_id)"></el-button>
            <!--删除-->
            <el-button type="danger" size="mini" autofocus v-if="is_del" icon="el-icon-delete-solid"
                       @click="removeParamssById(scope.row.gan_id)"></el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区 -->
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="querInfo.page"
          :page-sizes="[3,5,10,15]"
          :page-size="querInfo.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </el-card>
    <!-- 添加质量等级对话框-->
    <el-dialog
        title="添加属性名称" :visible.sync="addParamsDialogVisible" width="50%" @close="addqualitylevelDialogClose">
      <!--    添加属性名称的表单    -->
      <el-form :model="addparamsForm" :rules="addparamsFormRules" ref="addparamsFormRef" label-width="100px">
        <el-form-item label="属性名称：" prop="gan_name">
          <el-input v-model="addparamsForm.gan_name"></el-input>
        </el-form-item>



      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addParamsDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addParamsFrom">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 编辑质量等级对话框-->
    <el-dialog
        title="添加属性名称" :visible.sync="editParamsDialogVisible" width="50%" @close="editqualitylevelDialogClose">
      <!--    添加属性名称的表单    -->
      <el-form :model="editparamsForm" :rules="editparamsFormRules" ref="editparamsFormRef" label-width="100px">
        <el-form-item label="属性名称：" prop="gan_name">
          <el-input v-model="editparamsForm.gan_name"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editParamsDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editParamsFrom">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      //分页
      querInfo: {
        page: 1,
        limit: 10,
        c_id:''
      },
      total:0,
      //页面权限
      customerlevelJurisdiction:[],
      //添加按钮的展示与隐藏
      is_add:false,
      is_edit:false,
      is_del:false,
      is_goodsattrvalue_add:false,
      is_goodsattrvalue_edit:false,
      is_goodsattrvalue_del:false,
      //表格加载
      loading:true,
      //选中的父级分类的ID数组
      selectefKeys: [],
      //商品分类数据
      catelist: [],
      //指定级联选择器的配置对象
      cascaderProps: {
        expandTrigger: 'hover',
        // checkStrictly:true,
        value: 'c_id',
        label: 'c_name',
        children: 'children'
      },
      //动态参数的数据
      mantParamslist:[],
      //控制添加属性名称对话框的显示与隐藏
      addParamsDialogVisible:false,
      //控制添加属性名称对话框的显示与隐藏
      editParamsDialogVisible:false,
      //添加属性名称的 form 表单
      addparamsForm:{
        gan_name:'',
        c_id:'',
      },
      //编辑的form表单
      editparamsForm:{},
      //添加属性名称验证form
      addparamsFormRules:{
        gan_name:[
          { required: true, message: '请输入属性名称', trigger: 'blur' },
        ],
      },
      editparamsFormRules:{
        gan_name:[
          { required: true, message: '请输入属性名称', trigger: 'blur' },
        ],
      },
      inputVisible: false,
      inputValue: '',
      editable: [],
    }
  },
  created() {
    this.getCateList()
    this.getParamsJurisdiction()
  },
  methods: {
    //获取权限接口
    async getParamsJurisdiction(){
      this.loading=true
      let str = window.sessionStorage.getItem('activePath');
      let activePath = str.split("/").join("");
      const { data : res } = await this.$http.get('menu/getMenuChildrenList', {params:{m_path : activePath}})
      if (res.code!=200)return  this.$message.error(res.msg)
      this.ParamsJurisdiction=res.data
      this.ParamsJurisdiction.forEach((item)=>{
        if (item.m_path=='add'){
          this.is_add=true
        }else if (item.m_path=='edit'){
          this.is_edit=true
        }else if(item.m_path=='del'){
          this.is_del=true
        }else if(item.m_path=='goodsattrvalue_add'){
          this.is_goodsattrvalue_add=true
        }else if(item.m_path=='goodsattrvalue_edit'){
          this.is_goodsattrvalue_edit=true
        }else if(item.m_path=='goodsattrvalue_del'){
          this.is_goodsattrvalue_del=true
        }
      })
    },
    //监听pagesize改变
    handleSizeChange(newSize) {
      this.querInfo.limit = newSize
      this.getGoodsList()
    },
    //监听pagenum 改变
    handleCurrentChange(newPage) {
      this.querInfo.page = newPage
      this.getGoodsList ()
    },
    async getCateList() {
      const {data: res} = await this.$http.get('category/getMainList/3')
      if (res.code != 200) return this.$message.error(res.msg)
      this.catelist = res.data
    },

    //选择项发生变化触发这个函数
    parentCateChanged() {
      this.getparamslist()
      // this.addGoodsForm.g_cid = this.selectefKeys[this.selectefKeys.length - 1]
    },
    async getparamslist(){
      console.log(this.selectefKeys)
      this.querInfo.c_id=this.cataId
      console.log(this.querInfo.c_id)
      //根据所选分类的id 获取规格
      const {data: res} = await this.$http.get(`goodsattrname/getList`,
          {params: this.querInfo})
      if (res.code!=200)return this.$message.error(res.msg)
      res.data.data.forEach(item=>{
        item.inputVisible=false
        item.inputValue=''
      })
      this.mantParamslist=res.data.data
      console.log(this.mantParamslist)
      this.total=res.data.total
    },
    //添加属性名称
    addParamsOpen(){
      this.addParamsDialogVisible=true
    },
    //监听对话框的关闭事件
    addqualitylevelDialogClose(){
      this.addparamsForm.c_id=''
      this.addparamsForm.gan_name=''
      this.$refs.addparamsFormRef.resetFields()
    },
    editqualitylevelDialogClose(){
      this.$refs.editparamsFormRef.resetFields()
    },
    async addParamsFrom(){
      this.addparamsForm.c_id=this.cataId
      const {data:res} = await this.$http.post('goodsattrname/add',
          this.addparamsForm)
      if (res.code!=200)return this.$message.error(res.msg)
      this.addParamsDialogVisible=false
      this.$message.success(res.msg)
      this.getparamslist()
    },
    //编辑  查找当前id
    async editParams(id){
      this.editParamsDialogVisible=true
      const {data:res} = await this.$http.get(`goodsattrname/find/${id}`)
      if (res.code!=200)return this.$message.error(res.msg)
      this.editparamsForm=res.data
    },
    //提交编辑 qualitylevel/edit
    async editParamsFrom(){
      const {data:res} = await this.$http.post('goodsattrname/edit',
          this.editparamsForm)
      if (res.code!=200) return this.$message.error(res.msg)
      this.$message.success(res.msg)
      this.editParamsDialogVisible=false
      this.getparamslist()
    },
    //删除 qualitylevel/del/12
    async removeParamssById(id){
      const confirmResult = await this.$confirm(
          '此操作将永久删除该属性, 是否继续?',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }
      ).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已经取消删除')
      }
      const {data:res} = await this.$http.post(`goodsattrname/del/${id}`)
      if (res.code!=200)return this.$message.error(res.msg)
      this.$message.success(res.msg)
      this.getparamslist()

    },
    showInput(row) {
      row.inputVisible = true;
      console.log(row.inputVisible)

      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    //============编辑职位=====================
    //编辑 职位信息 input显示
    showEditTagInput(index,row,item) {
      // console.log(index,row,item,123)
      // console.log(this.is_goodsattrvalue_edit,456)
      if (this.is_goodsattrvalue_edit){
        // item.label=item.gav_name
        this.$set(this.editable, index, true);
        console.log(this.editable, index,123)
        this.$nextTick((_) => {
          var editableInput = "editableInput"+ item.gan_id + index;
          let edxaasd='edxaasd'
          console.log(this.$refs[edxaasd])
          // console.log(editableInput,123456)
          console.log(this.$refs[editableInput][0])
          this.$refs[editableInput][0].$refs.input.focus();
        });
      }else{
        return
      }

    },
    //编辑 职位信息 input发生改变
    async handleEditableInputConfirm(item, index, row) {
      if (item.gav_name) {
        this.$set(this.editable, index, false);
        const {data:res} = await this.$http.post('goodsattrvalue/edit',
            {gav_name:item.gav_name,gav_id:item.gav_id,gan_id:item.gan_id})
        if (res.code!=200) return this.$message.error(res.msg)
          this.$message.success(res.msg)
          for (let i=0;i<this.mantParamslist.length;i++){
            if(this.mantParamslist[i].gan_id==item.gan_id){
              console.log(item,123)
              this.mantParamslist[i].children[index].gav_name=item.gav_name
            }
          }
        } else {
          this.$message({message: "请输入职位信息", type: "info"});
        }
    },
    //编辑  input失去焦点
    handleEditableInputBlur(item, index) {
      this.$set(this.editable, index, false);
    },

    async handleInputConfirm(row) {
      if (row.inputValue.trim().length === 0){
        row.inputValue = '';
        row.inputVisible = false;
        return
      }
      //如果没有return 则证明输入的内容 需要做后续的处理
      const {data:res} = await this.$http.post('goodsattrvalue/add',
          {'gan_id':row.gan_id,
                'gav_name':row.inputValue
          })
      if(res.code!=200) return this.$message.error(res.msg)
      this.$message.success(res.msg)
      row.children.push({'gav_name':row.inputValue.trim(),'gav_id':res.data.gav_id})

      row.inputValue = '';
      row.inputVisible = false;

    },
    //删除
    async handleClose(index,row){
      console.log(index,row.children[index].gav_id)
      const {data:res} = await this.$http.post(`goodsattrvalue/del/${row.children[index].gav_id}`)
      if (res.code!=200) return this.$message.error(res.msg)
      this.$message.success(res.msg)
      row.children.splice(index,1)

    },
    handleClick(index,row){
      console.log(row.children[index])
      row.inputVisible = true;
      this.$nextTick(()=>{
        this.$refs.saveTagInput.$refs.input.focus()
      })
      row.inputValue=row.children[index].gav_name

    }


  },
  computed: {
    isBtnDisabled() {
      //如果按钮需要被禁用，则返回true  否则返回false
      if (this.selectefKeys.length !== 3) {
        return true
      }
      return false
    },
    //当前选中的三级分类id
    cataId(){
      if(this.selectefKeys.length === 3){
        return this.selectefKeys[2]
      }
      return null
    }
  }
}
</script>
<style lang="less" scoped>
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 250px;
  margin-left: 10px;
  vertical-align: bottom;
}
.el-table__expanded-cell span{
  margin-bottom: 10px;
}
.inline-box{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.tag-div{
  margin-right: 5px;
}
.el-cascader{
  width: 500px;
}
.input-new-tag{
  width: 200px;
}
</style>
